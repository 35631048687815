.main {
    width: 100%;
    margin: 0;
    position: relative;

    h1 {
        position: absolute;
        top: calc(50% - 100px);
        width: 100%;
        color: white;
        font-weight: 500;
        line-height: 58px;
        text-align: center;
    }
}

.background {
    height: calc(100% - 4px);
    width: 100%;
}

.dark-mode-video {
    height: calc(100% - 3px);
    width: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    position: absolute;
    top: 0;
    left: 0;
}

.videoTag {
    max-width: 100%;
    max-height: 100%;
    margin: 0;
}

.mainCounterBox {
    font-family: "Fira_Sans light";
    font-weight: lighter;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    text-align: center;
    width: 920px;
    color: white;
    margin: 0 auto;
    position: absolute;
    bottom: 150px;
    left: calc(50% - 460px);

    .counterItem {
        span {
            font-size: 3.3rem;
            color: white;
            transition: all 0.3s ease;

            &:hover {
                color: #E24E4E !important;
                cursor: default;
            }
        }

        p {
            font-size: 1.5rem;
            color: rgba(188, 188, 188, 1);

            &:hover {
                cursor: default;
            }
        }
    }
}