.story {
    h1 {
        color: white;
        text-align: center;
        padding: 50px 0 50px 0;
    }

    .videoTag {
        width: 100%;
    }

    &Title {
        color: white;
        font-size: 1.5rem;
        padding: 25px 0 50px 0;
    }
}