* {
    font-family: 'Fira_Sans';
}

h1 {
    font-size: 3.2rem;
}

html {
    overflow-x: hidden;
    background: rgba(45, 45, 45, 1);
}

svg {
    display: inline-block;
    vertical-align: middle;
}
