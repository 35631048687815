.mobileLanguageChange {
    display: none;

    .changeLeng {
        display: flex;
        flex-direction: column;
        align-items: center;

        button {
            color: #BCBCBC;
            text-decoration: none;
            transition: all .3s ease;
            margin: 0 15px;
            background: none;
            border: none;
            font-style: normal;
            font-weight: 500;
            font-size: 1rem;
            line-height: 18px;
        }

        &:after {
            display: none !important;
        }
    }
}