.gallery {
  background: #f6f8f8;
  padding: 50px 0;

  h1 {
    text-align: center;
    color: rgba(60, 60, 60, 1);
    margin-bottom: 30px;
  }
}

.galleryBox {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); 
  gap: 15px;
  justify-content: center;
  max-width: 90%;
  margin: 0 auto;
}

.galleryImageBox {
  width: 100%;
  aspect-ratio: 1 / 1; 
  overflow: hidden;
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

  img {
    width: 100%;
    height: 100%;
    object-fit: cover; 
    border-radius: 10px;
  }

  &:hover {
    transform: scale(1.05);
  }
}


.lightboxOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.lightbox-content {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.lightbox-image {
  max-width: 80vw;
  max-height: 90vh;
  object-fit: contain;
  transition: transform 0.3s ease-out;
}

.lightbox-close {
  position: absolute;
  top: 15px;
  right: 15px;
  background: none;
  border: none;
  font-size: 30px;
  color: white;
  cursor: pointer;
  z-index: 1001;
}


.lightbox-prev,
.lightbox-next {
  position: fixed; /* Change to fixed to position them relative to the viewport */
  top: 50%;
  transform: translateY(-50%);
  background: rgba(255, 255, 255, 0.2);
  border: none;
  font-size: 50px;
  color: white;
  padding: 10px;
  cursor: pointer;
  z-index: 1001;
  transition: opacity 0.3s ease-in-out;
  opacity: 0;
}
.lightboxOverlay:hover .lightbox-prev,
.lightboxOverlay:hover .lightbox-next {
  opacity: 1;
}

.lightbox-prev {
  left: 20px;
}

.lightbox-next {
  right: 20px;
}


@media (max-width: 768px) {
  .galleryBox {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr)); /* Для мобильных */
  }

  .lightbox-prev,
  .lightbox-next {
    display: none;
  }
}
