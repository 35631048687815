@font-face {
    font-family: "Fira_Sans";
    src: url("../../fonts/Fira_Sans/FiraSans-Regular.ttf") format("truetype");
    font-style: normal;
    font-weight: normal;
}

@font-face {
    font-family: "Fira_Sans Bold";
    src: url("../../fonts/Fira_Sans/FiraSans-Bold.ttf") format("truetype");
    font-style: normal;
    font-weight: bold;
}

@font-face {
    font-family: "Fira_Sans light";
    src: url("../../fonts/Fira_Sans/FiraSans-Light.ttf") format("truetype");
    font-style: normal;
    font-weight: normal;
}