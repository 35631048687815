.header {
  height: 100px;
  background-color: rgba(35, 35, 35, 0.9);
  z-index: 9;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;

  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    padding: 0 20px; /* Add padding to the content for spacing */
  }

  a {
    text-decoration: none;
  }

  .nav {
    display: flex;
    justify-content: space-around;
    align-items: center;
    max-width: 70%;
    height: 100%;
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;

    .nav-links {
      color: #bcbcbc;
      text-decoration: none;
      padding: 15px;
      margin: 0 15px;
      transition: all 0.3s ease;
    }

    .nav-contacts {
      display: flex;
      flex-direction: column;

      .contact-numbers {
        color: #bcbcbc;
        text-decoration: none;
        margin: 5px 15px;
        transition: all 0.3s ease;
      }
    }

    .nav-links:hover,
    .leng-links:hover,
    .contact-numbers:hover,
    .footer-nav-links:hover,
    .leng-links-footer:hover,
    .footer-cont-num:hover {
      color: #e24e4e;
      cursor: pointer;
    }

    .leng-links {
      color: #bcbcbc;
      text-decoration: none;
      transition: all 0.3s ease;
      margin: 0 15px;
      background: none;
      border: none;
      font-style: normal;
      font-weight: 500;
      font-size: 1rem;
      line-height: 18px;
    }

    .changeLeng {
      border: 1px solid #e24e4e;
      transform: rotate(90deg);
      left: calc(50% + 5px);
      top: 50%;
    }
  }

  height: 100px;
  background-color: rgba(35, 35, 35, 0.9);
  z-index: 9;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
}

.logo-box {
  display: flex;
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 18px;
  color: #bfbfbf;
  align-items: center;

  img {
    height: 60px;
    margin-right: 30px;
  }
}
