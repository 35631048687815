.videoModalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.9);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.videoModalContent {
    position: relative;
    max-width: 90%;
    max-height: 90%;
    background: black;
}


.newsImageBox *{
    max-width: 100%;
    max-height: 100%;
}

.news_section {
    background: #f6f8f8;
}

.news_section h1 {
    color: rgba(60, 60, 60, 1);
    width: 100%;
    text-align: center;
    margin: 0;
    padding: 50px 0;
    font-family: "Fira_Sans Bold";
}

.newsDate {
    position: absolute;
    bottom: 5px;
    right: 5px;
    margin: 0;

    p {
        margin: 0;
        font-size: 0.7rem;
        color: white;
    }
}

.newsGalleryImageBox img,
.newsGalleryImageBox video {
    height: 100%;
    max-width: 100%;
    object-fit: contain;
}

.newsGalleryImageBox {
    height: 100%;
}

.newsPreviewBox {
    padding-bottom: 50px;

    .content {
        display: flex;
        justify-content: center;
        align-items: flex-start;
    }
}

.newsImageBox {
    display: flex;
    justify-content: center;
    height: 400px;
    position: relative;
}

.newsImageBox,
.newsPreviewTitleBox {
    width: 570px;
    margin: 0 15px;
}

.newsPreviewTitleBox {
    position: relative;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    h3 {
        color: rgba(60, 60, 60, 1);
        margin: 10px 0 0;
        font-size: 2.3rem;
        font-family: "Fira_Sans Bold";
    }
}

.newsPreviewImage {
    max-height: 100%;
}

.date {
    margin: 0;

    p {
        margin: 0;
        font-size: 1.3rem;
        color: #BFBFBF;
    }
}

.newsPreviewTitle {
    font-size: 1.3rem;
    margin-top: 30px;
}

.box-for-news-gallery {
    padding-bottom: 50px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.previewButtonBox button {
    height: 50px;
    width: 200px;
    border-radius: 0;
    background: transparent;
    font-size: 2rem;
    text-align: center;
    transition: all .3s ease;
    border: Solid #E24E4E;
    color: rgba(226, 78, 78, 1) !important;
}

.newsGalleryBox {
    width: 270px;
    height: 270px;
    position: relative;
    margin: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(60, 60, 60, .2);

    .newsGalleryTitleBox {
        position: absolute;
        bottom: 0;
        left: 0;
        height: auto;
        background: rgba(45, 45, 45, 0.7);
        width: 100%;

        .newsGalleryTitle {
            color: white;
            font-size: 1rem;
            font-family: "Fira_Sans";
            padding: 15px 15px 25px 15px;
            margin: 0;
        }
    }
}

.news_section_content {
    display: flex;
    flex-direction: column;
    padding-bottom: 50px;
}

.load-more-button {
    background-color: grey;
    transition: all .2s;
    color: white;
    border: none;
    font-size: 1.5rem;
    padding: 25px;
    align-self: center;

    &:hover {
        background-color: #E24E4E;
        cursor: pointer;
        transform: scale(1.02);
    }
}

/* Убираем фон Lightbox (делаем прозрачным) */

.yarl__container {
    background: rgba(0, 0, 0, 0.8) !important;
}


/* Центрируем изображение */
.yarl__container {
    background: transparent !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Убираем стрелки */
.yarl__navigation_next,
.yarl__navigation_prev {
    display: none !important;
}

/* Подгоняем изображение по размеру */
.yarl__slide img {
    max-width: 90vw;
    max-height: 90vh;
    object-fit: contain;
}


.gallery-media {
  width: 100%;
  height: 100%;
  object-fit: cover;
}


.lightboxOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}

.lightbox-slide {
  position: relative;
  max-width: 90vw;
  max-height: 90vh;
}

.lightbox-media {
  max-width: 90vw; /* 90% ширины окна */
  max-height: 90vh; /* 90% высоты окна */
  width: auto;
  height: auto;
  object-fit: contain; /* Подгоняем размер, не искажая видео */
}

.lightbox-caption {
  position: absolute;
  top: 0;
  left: 0;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}

/* Фон Lightbox */
.lightboxOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Контейнер Lightbox */
.lightboxContent {
  position: relative;
  max-width: 90vw;
  max-height: 90vh;
}

/* Видео-модалка */
.videoLightbox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1000;
}

.videoOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
}

.videoContent {
  position: relative;
  z-index: 1001;
  max-width: 90%;
  max-height: 90%;
}

.modal-video {
  max-height: 80vh;
  width: auto;
  object-fit: contain;
}