@media screen and (min-width: 1400px) {
    html {
        font-size: 16px;
    }

    .content {
        width: 1200px;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1400px) {
    html {
        font-size: 16px;
    }

    .content {
        width: 1200px;
    }

    .mainCounterBox {
        bottom: 50px !important;
    }
}

@media screen and (min-width: 1080px) and (max-width: 1200px) {
    html {
        font-size: 15px;
    }

    .content {
        width: 1080px;
    }

    .mainCounterBox {
        bottom: 50px !important;
    }
}

@media screen and (min-width: 945px) and (max-width: 1079px) {
    html {
        font-size: 11px;
    }

    .content {
        max-width: 100%;
    }

    .mainCounterBox {
        bottom: 50px !important;
    }
}

@media screen and (min-width: 600px) and (max-width: 920px) {
    .mainCounterBox {
        width: 600px !important;
        left: calc(50% - 300px) !important;
    }

    .nav-contacts {
        display: none !important;
    }

    .changeLeng::after {
        display: none !important;
    }

    .newsPreviewBox {
        height: auto !important;
    }

    .newsPreviewBox .content {
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }

    .previewButtonBox {
        position: relative !important;
    }

    .newsPreviewTitleBox {
        text-align: center;
        justify-content: center;
    }

    .newsImageBox {
        justify-content: center !important;
    }
}

@media screen and (min-width: 600px) and (max-width: 944px) {
    html {
        font-size: 9px;
    }

    .content {
        max-width: 100%;
    }

    .mainCounterBox {
        bottom: 30px !important;
    }
}

@media screen and (max-width: 600px) {
    html {
        font-size: 10px;
    }

    html * {
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }

    .content {
        width: 320px;
    }
    .nav {
        display: none !important;
    }
    
    .coordinatorsPerson:nth-child(2){
        flex-direction: column-reverse;
    }
    .main {
        margin: 100px 0 0 !important;
    }

    .newsPreviewBox {
        height: auto !important;
    }

    .gallery {
        display: none !important;
    }

    .coordinators {
        text-align: center !important;
        padding: 25px 0 40px 0 !important;
    }

    .coordinatorsPerson:nth-child(2) {
        text-align: center !important;
    }

    .newsGalleryBox {
        margin: 10px !important;
    }

    .header .content,
    .header .logo-box {
        flex-wrap: nowrap;
    }

    .footer .content {
        flex-direction: column;
        align-items: center !important;
        text-align: center !important;
    }

    .footer .content>div {
        margin: 15px 0;
    }

    .mobileLanguageChange {
        display: block !important;
    }

    .projectCardBox {
        height: 215px !important;
    }

    .story h1 {
        padding: 0 0 50px !important;
        margin: 0 !important;
    }

    .main h1 {
        font-size: 2.2rem !important;
    }

    .mainCounterBox {
        display: none !important;
    }

    .previewButtonBox {
        position: relative !important;
    }
}