.hide {
    display: none;
}
.hidden {
    display: none;
}
.content {
    margin: 0 auto;
}
.app-wrapper {
    width: 100vw;
}
source {
    margin: 0;
}
.big {
    font-size: 1rem;
    fill: #fff;
}

.small {
    font-size: .6rem;
    fill: #fff;
}

.transparent {
    fill: rgba(255, 255, 255, 0.1) !important;
    transition: all .2s ease;
}

.transparent:hover {
    fill: #fff !important;
    text-shadow: black 0 0 5px;
}
